<template>
  <div>
    <billing-info
      v-if="firstScreen"
      :from-receiver="true"
      :billing-info="billingInfo"
      @set-billing-info="setBillingInfo"
      @cancel-action="exit"
      @next-action="firstScreen = false"
    />
    <zubut-selection
      v-else
      :drivers-billing-info="billingInfo"
      :from-receiver="true"
      @back-action="firstScreen = true"
      @cancel-action="exit"
    />
  </div>
</template>

<script>
import BillingInfo from "@/app/views/Invoice/ExcelInvoice/BillingInfo.vue";
import ZubutSelection from "@/app/views/Invoice/ExcelInvoice/ZubutSelection.vue";

export default {
  name: "ExcelInvoiceReceiver",

  components: {
    BillingInfo,
    ZubutSelection
  },

  data() {
    return {
      billingInfo: [],
      firstScreen: true
    };
  },

  methods: {
    setBillingInfo(data) {
      this.billingInfo = data;
    },

    exit() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss"></style>
